import { FC, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Flex, Text, VStack } from '@chakra-ui/react'

import {
  ModalView,
  PrimaryButton,
  SecondaryButton,
  SemanticColors,
  Spacing,
  TertiaryButton,
  Typography,
} from '@enechain/ecloud-designsystem'

import { Term, TermAgreementStatus } from '~/gen/proto/bff/v1/model_pb'

import { useAgreeToTerm } from '../hooks/useAgreeToTerm'
import { usePendingTerms } from '../hooks/usePendingTerms'

export const TermsAgreementModal: FC = () => {
  const _terms = usePendingTerms()
  const [terms, setTerms] = useState(_terms)
  const { t: en } = useTranslation('common', { lng: 'en', keyPrefix: 'pages' })
  const { t: ja } = useTranslation('common', { lng: 'ja', keyPrefix: 'pages' })

  const isAlreadyConfirmed = useMemo(() => {
    return _terms.every((term) => term.status === TermAgreementStatus.AGREED)
  }, [_terms])

  const isAllTermReviewed = useMemo(() => {
    return terms.every((term) => term.status === TermAgreementStatus.AGREED)
  }, [terms])

  const onClickUrl = (term: Term): void => {
    window.open(term.url, '_blank')
    const newTerms = terms.map((term) => {
      if (term.version === term.version) {
        term.status = TermAgreementStatus.AGREED
      }
      return term
    })
    setTerms(newTerms)
  }

  const { mutate, isPending } = useAgreeToTerm()
  const onAgree = (): void => {
    mutate({ terms: terms })
  }

  return (
    <ModalView
      bodyChildren={
        <Box>
          <VStack
            alignItems="start"
            paddingTop={Spacing[2]}
            paddingBottom={Spacing[4]}
            spacing={Spacing[4]}
          >
            <Text {...Typography.textSm}>
              {ja('term.description.paragraph1')}
              <br />
              {en('term.description.paragraph1')}
            </Text>
            <Text {...Typography.textSm}>
              {ja('term.description.paragraph2')}
              <br />
              {en('term.description.paragraph2')}
            </Text>
            <Text
              {...Typography.textSm}
              color={SemanticColors.Text.negativeMid}
            >
              {ja('term.description.paragraph3')}
              <br />
              {en('term.description.paragraph3')}
            </Text>
          </VStack>
          <VStack
            alignItems="stretch"
            overflowY="scroll"
            paddingX={Spacing[8]}
            paddingY={Spacing[4]}
            spacing={Spacing[4]}
          >
            {terms.map((term, i) => (
              <Flex
                key={`terms-${i}`}
                alignItems="center"
                justifyContent="space-between"
              >
                <Text textStyle="headerMd">{term.name}</Text>
                {term.status === TermAgreementStatus.AGREED ? (
                  <TertiaryButton onClick={() => onClickUrl(term)}>
                    {ja('term.description.reviewed')}&nbsp;/&nbsp;
                    {en('term.description.reviewed')}
                  </TertiaryButton>
                ) : (
                  <SecondaryButton onClick={() => onClickUrl(term)}>
                    {ja('term.description.review')}&nbsp;/&nbsp;
                    {en('term.description.review')}
                  </SecondaryButton>
                )}
              </Flex>
            ))}
          </VStack>
        </Box>
      }
      displayCloseButton={false}
      footerChildren={
        <PrimaryButton
          isDisabled={!isAllTermReviewed}
          isLoading={isPending}
          onClick={onAgree}
        >
          {ja('term.description.accept')}&nbsp;/&nbsp;
          {en('term.description.accept')}
        </PrimaryButton>
      }
      isOpen={!isAlreadyConfirmed}
      onClose={(): void => {
        return
      }}
      onEsc={(): void => {
        return
      }}
      size="5xl"
      title="利用規約 / Terms of Service"
    />
  )
}
