// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file bff/v1/admin.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Language, Member, MemberRole, Term } from "./model_pb.js";

/**
 * @generated from message bff.v1.SendInvitationRequest
 */
export class SendInvitationRequest extends Message<SendInvitationRequest> {
  /**
   * @generated from field: string member_id = 1;
   */
  memberId = "";

  constructor(data?: PartialMessage<SendInvitationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.SendInvitationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendInvitationRequest {
    return new SendInvitationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendInvitationRequest {
    return new SendInvitationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendInvitationRequest {
    return new SendInvitationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SendInvitationRequest | PlainMessage<SendInvitationRequest> | undefined, b: SendInvitationRequest | PlainMessage<SendInvitationRequest> | undefined): boolean {
    return proto3.util.equals(SendInvitationRequest, a, b);
  }
}

/**
 * @generated from message bff.v1.SendInvitationResponse
 */
export class SendInvitationResponse extends Message<SendInvitationResponse> {
  constructor(data?: PartialMessage<SendInvitationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.SendInvitationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SendInvitationResponse {
    return new SendInvitationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SendInvitationResponse {
    return new SendInvitationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SendInvitationResponse {
    return new SendInvitationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SendInvitationResponse | PlainMessage<SendInvitationResponse> | undefined, b: SendInvitationResponse | PlainMessage<SendInvitationResponse> | undefined): boolean {
    return proto3.util.equals(SendInvitationResponse, a, b);
  }
}

/**
 * @generated from message bff.v1.GetMemberRequest
 */
export class GetMemberRequest extends Message<GetMemberRequest> {
  /**
   * @generated from field: string member_id = 1;
   */
  memberId = "";

  constructor(data?: PartialMessage<GetMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetMemberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberRequest {
    return new GetMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberRequest {
    return new GetMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberRequest {
    return new GetMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberRequest | PlainMessage<GetMemberRequest> | undefined, b: GetMemberRequest | PlainMessage<GetMemberRequest> | undefined): boolean {
    return proto3.util.equals(GetMemberRequest, a, b);
  }
}

/**
 * @generated from message bff.v1.GetMemberResponse
 */
export class GetMemberResponse extends Message<GetMemberResponse> {
  /**
   * @generated from field: bff.v1.Member member = 1;
   */
  member?: Member;

  constructor(data?: PartialMessage<GetMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.GetMemberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member", kind: "message", T: Member },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberResponse {
    return new GetMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberResponse {
    return new GetMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberResponse {
    return new GetMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberResponse | PlainMessage<GetMemberResponse> | undefined, b: GetMemberResponse | PlainMessage<GetMemberResponse> | undefined): boolean {
    return proto3.util.equals(GetMemberResponse, a, b);
  }
}

/**
 * @generated from message bff.v1.SaveMemberRequest
 */
export class SaveMemberRequest extends Message<SaveMemberRequest> {
  /**
   * メンバーID
   *
   * @generated from field: optional string id = 1;
   */
  id?: string;

  /**
   * 所属組織ID
   *
   * @generated from field: string organizationId = 2;
   */
  organizationId = "";

  /**
   * メンバーのメールアドレス
   *
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * メンバーの電話番号
   *
   * @generated from field: string phoneNumber = 4;
   */
  phoneNumber = "";

  /**
   * メンバーの携帯電話番号
   *
   * @generated from field: string mobileNumber = 5;
   */
  mobileNumber = "";

  /**
   * メンバーの役割
   *
   * @generated from field: bff.v1.MemberRole role = 6;
   */
  role = MemberRole.UNKNOWN;

  /**
   * メンバー名
   *
   * @generated from field: string name = 7;
   */
  name = "";

  /**
   * ユーザーロールコード
   *
   * @generated from field: repeated string userRoleCodes = 8;
   */
  userRoleCodes: string[] = [];

  /**
   * 言語設定
   *
   * @generated from field: bff.v1.Language language = 9;
   */
  language = Language.UNKNOWN;

  constructor(data?: PartialMessage<SaveMemberRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.SaveMemberRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "organizationId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phoneNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "mobileNumber", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "role", kind: "enum", T: proto3.getEnumType(MemberRole) },
    { no: 7, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "userRoleCodes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "language", kind: "enum", T: proto3.getEnumType(Language) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveMemberRequest {
    return new SaveMemberRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveMemberRequest {
    return new SaveMemberRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveMemberRequest {
    return new SaveMemberRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveMemberRequest | PlainMessage<SaveMemberRequest> | undefined, b: SaveMemberRequest | PlainMessage<SaveMemberRequest> | undefined): boolean {
    return proto3.util.equals(SaveMemberRequest, a, b);
  }
}

/**
 * @generated from message bff.v1.SaveMemberResponse
 */
export class SaveMemberResponse extends Message<SaveMemberResponse> {
  /**
   * @generated from field: bff.v1.Member member = 1;
   */
  member?: Member;

  constructor(data?: PartialMessage<SaveMemberResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.SaveMemberResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "member", kind: "message", T: Member },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveMemberResponse {
    return new SaveMemberResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveMemberResponse {
    return new SaveMemberResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveMemberResponse {
    return new SaveMemberResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SaveMemberResponse | PlainMessage<SaveMemberResponse> | undefined, b: SaveMemberResponse | PlainMessage<SaveMemberResponse> | undefined): boolean {
    return proto3.util.equals(SaveMemberResponse, a, b);
  }
}

/**
 * 規約への同意リクエスト
 *
 * @generated from message bff.v1.AgreeToTermRequest
 */
export class AgreeToTermRequest extends Message<AgreeToTermRequest> {
  /**
   * @generated from field: repeated bff.v1.Term terms = 1;
   */
  terms: Term[] = [];

  constructor(data?: PartialMessage<AgreeToTermRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.AgreeToTermRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "terms", kind: "message", T: Term, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgreeToTermRequest {
    return new AgreeToTermRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgreeToTermRequest {
    return new AgreeToTermRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgreeToTermRequest {
    return new AgreeToTermRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AgreeToTermRequest | PlainMessage<AgreeToTermRequest> | undefined, b: AgreeToTermRequest | PlainMessage<AgreeToTermRequest> | undefined): boolean {
    return proto3.util.equals(AgreeToTermRequest, a, b);
  }
}

/**
 * 規約への同意レスポンス
 *
 * @generated from message bff.v1.AgreeToTermResponse
 */
export class AgreeToTermResponse extends Message<AgreeToTermResponse> {
  constructor(data?: PartialMessage<AgreeToTermResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.AgreeToTermResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AgreeToTermResponse {
    return new AgreeToTermResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AgreeToTermResponse {
    return new AgreeToTermResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AgreeToTermResponse {
    return new AgreeToTermResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AgreeToTermResponse | PlainMessage<AgreeToTermResponse> | undefined, b: AgreeToTermResponse | PlainMessage<AgreeToTermResponse> | undefined): boolean {
    return proto3.util.equals(AgreeToTermResponse, a, b);
  }
}

/**
 * 規約一覧リクエスト
 *
 * @generated from message bff.v1.ListPendingTermsRequest
 */
export class ListPendingTermsRequest extends Message<ListPendingTermsRequest> {
  constructor(data?: PartialMessage<ListPendingTermsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListPendingTermsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPendingTermsRequest {
    return new ListPendingTermsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPendingTermsRequest {
    return new ListPendingTermsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPendingTermsRequest {
    return new ListPendingTermsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListPendingTermsRequest | PlainMessage<ListPendingTermsRequest> | undefined, b: ListPendingTermsRequest | PlainMessage<ListPendingTermsRequest> | undefined): boolean {
    return proto3.util.equals(ListPendingTermsRequest, a, b);
  }
}

/**
 * 規約一覧レスポンス
 *
 * @generated from message bff.v1.ListPendingTermsResponse
 */
export class ListPendingTermsResponse extends Message<ListPendingTermsResponse> {
  /**
   * @generated from field: repeated bff.v1.Term terms = 1;
   */
  terms: Term[] = [];

  constructor(data?: PartialMessage<ListPendingTermsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.ListPendingTermsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "terms", kind: "message", T: Term, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListPendingTermsResponse {
    return new ListPendingTermsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListPendingTermsResponse {
    return new ListPendingTermsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListPendingTermsResponse {
    return new ListPendingTermsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListPendingTermsResponse | PlainMessage<ListPendingTermsResponse> | undefined, b: ListPendingTermsResponse | PlainMessage<ListPendingTermsResponse> | undefined): boolean {
    return proto3.util.equals(ListPendingTermsResponse, a, b);
  }
}

