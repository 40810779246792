// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file bff/v1/model.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * ユーザーロールステータスのモデル
 *
 * @generated from enum bff.v1.UserRoleStatus
 */
export enum UserRoleStatus {
  /**
   * 不明
   *
   * @generated from enum value: USER_ROLE_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * 有効
   *
   * @generated from enum value: USER_ROLE_STATUS_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * 無効
   *
   * @generated from enum value: USER_ROLE_STATUS_INACTIVE = 2;
   */
  INACTIVE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UserRoleStatus)
proto3.util.setEnumType(UserRoleStatus, "bff.v1.UserRoleStatus", [
  { no: 0, name: "USER_ROLE_STATUS_UNKNOWN" },
  { no: 1, name: "USER_ROLE_STATUS_ACTIVE" },
  { no: 2, name: "USER_ROLE_STATUS_INACTIVE" },
]);

/**
 * メンバーの役割のモデル
 *
 * @generated from enum bff.v1.MemberRole
 */
export enum MemberRole {
  /**
   * 不明
   *
   * @generated from enum value: MEMBER_ROLE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * 管理者
   *
   * @generated from enum value: MEMBER_ROLE_ADMIN = 1;
   */
  ADMIN = 1,

  /**
   * メンバー
   *
   * @generated from enum value: MEMBER_ROLE_MEMBER = 2;
   */
  MEMBER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(MemberRole)
proto3.util.setEnumType(MemberRole, "bff.v1.MemberRole", [
  { no: 0, name: "MEMBER_ROLE_UNKNOWN" },
  { no: 1, name: "MEMBER_ROLE_ADMIN" },
  { no: 2, name: "MEMBER_ROLE_MEMBER" },
]);

/**
 * メンバーの状態のモデル
 *
 * @generated from enum bff.v1.MemberStatus
 */
export enum MemberStatus {
  /**
   * 不明
   *
   * @generated from enum value: MEMBER_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * 招待中
   *
   * @generated from enum value: MEMBER_STATUS_INVITED = 1;
   */
  INVITED = 1,

  /**
   * アクティブ
   *
   * @generated from enum value: MEMBER_STATUS_ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * 削除済み
   *
   * @generated from enum value: MEMBER_STATUS_DELETED = 3;
   */
  DELETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(MemberStatus)
proto3.util.setEnumType(MemberStatus, "bff.v1.MemberStatus", [
  { no: 0, name: "MEMBER_STATUS_UNKNOWN" },
  { no: 1, name: "MEMBER_STATUS_INVITED" },
  { no: 2, name: "MEMBER_STATUS_ACTIVE" },
  { no: 3, name: "MEMBER_STATUS_DELETED" },
]);

/**
 * プラン種別のモデル
 *
 * @generated from enum bff.v1.PlanType
 */
export enum PlanType {
  /**
   * 不明
   *
   * @generated from enum value: PLAN_TYPE_UNKNOWN = 0;
   */
  PLAN_TYPE_UNKNOWN = 0,

  /**
   * ベース
   *
   * @generated from enum value: BASE = 1;
   */
  BASE = 1,

  /**
   * オプション
   *
   * @generated from enum value: OPTION = 2;
   */
  OPTION = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PlanType)
proto3.util.setEnumType(PlanType, "bff.v1.PlanType", [
  { no: 0, name: "PLAN_TYPE_UNKNOWN" },
  { no: 1, name: "BASE" },
  { no: 2, name: "OPTION" },
]);

/**
 * ライセンス契約モデル
 *
 * @generated from enum bff.v1.LicenseContractType
 */
export enum LicenseContractType {
  /**
   * 不明
   *
   * @generated from enum value: CONTRACT_TYPE_UNKNOWN = 0;
   */
  CONTRACT_TYPE_UNKNOWN = 0,

  /**
   * 固定期間契約
   *
   * @generated from enum value: TERM = 1;
   */
  TERM = 1,

  /**
   * 無期限契約
   *
   * @generated from enum value: EVERGREEN = 2;
   */
  EVERGREEN = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(LicenseContractType)
proto3.util.setEnumType(LicenseContractType, "bff.v1.LicenseContractType", [
  { no: 0, name: "CONTRACT_TYPE_UNKNOWN" },
  { no: 1, name: "TERM" },
  { no: 2, name: "EVERGREEN" },
]);

/**
 * ライセンスのステータスのモデル
 *
 * @generated from enum bff.v1.LicenseStatus
 */
export enum LicenseStatus {
  /**
   * 不明
   *
   * @generated from enum value: LICENSE_STATUS_UNKNOWN = 0;
   */
  LICENSE_STATUS_UNKNOWN = 0,

  /**
   * アクティブ
   *
   * @generated from enum value: LICENSE_ACTIVE = 1;
   */
  LICENSE_ACTIVE = 1,

  /**
   * 有効期限切れ
   *
   * @generated from enum value: LICENSE_TERMINATED = 2;
   */
  LICENSE_TERMINATED = 2,

  /**
   * 未来に有効になる
   *
   * @generated from enum value: LICENSE_EFFECTIVE_IN_FUTURE = 3;
   */
  LICENSE_EFFECTIVE_IN_FUTURE = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(LicenseStatus)
proto3.util.setEnumType(LicenseStatus, "bff.v1.LicenseStatus", [
  { no: 0, name: "LICENSE_STATUS_UNKNOWN" },
  { no: 1, name: "LICENSE_ACTIVE" },
  { no: 2, name: "LICENSE_TERMINATED" },
  { no: 3, name: "LICENSE_EFFECTIVE_IN_FUTURE" },
]);

/**
 * 言語のモデル
 *
 * @generated from enum bff.v1.Language
 */
export enum Language {
  /**
   * 不明
   *
   * @generated from enum value: LANGUAGE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * 日本語
   *
   * @generated from enum value: Language_JAPANESE = 1;
   */
  JAPANESE = 1,

  /**
   * 英語
   *
   * @generated from enum value: Language_ENGLISH = 2;
   */
  ENGLISH = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Language)
proto3.util.setEnumType(Language, "bff.v1.Language", [
  { no: 0, name: "LANGUAGE_UNKNOWN" },
  { no: 1, name: "Language_JAPANESE" },
  { no: 2, name: "Language_ENGLISH" },
]);

/**
 * 規約の同意ステータス
 *
 * @generated from enum bff.v1.TermAgreementStatus
 */
export enum TermAgreementStatus {
  /**
   * 不明
   *
   * @generated from enum value: TERM_AGREEMENT_STATUS_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * 同意済み
   *
   * @generated from enum value: TERM_AGREEMENT_STATUS_AGREED = 1;
   */
  AGREED = 1,

  /**
   * 同意前
   *
   * @generated from enum value: TERM_AGREEMENT_STATUS_PENDING = 2;
   */
  PENDING = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TermAgreementStatus)
proto3.util.setEnumType(TermAgreementStatus, "bff.v1.TermAgreementStatus", [
  { no: 0, name: "TERM_AGREEMENT_STATUS_UNKNOWN" },
  { no: 1, name: "TERM_AGREEMENT_STATUS_AGREED" },
  { no: 2, name: "TERM_AGREEMENT_STATUS_PENDING" },
]);

/**
 * プロダクトのモデル
 *
 * @generated from message bff.v1.Product
 */
export class Product extends Message<Product> {
  /**
   * プロダクトID
   *
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * プロダクト名
   *
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * プロダクトのTOPページURL
   *
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * ユーザー表示フラグ、これがtrueであればeCloudなどでユーザー向けに表示される
   *
   * @generated from field: bool is_public = 4;
   */
  isPublic = false;

  /**
   * 表示順序
   *
   * @generated from field: int32 sort_order = 5;
   */
  sortOrder = 0;

  constructor(data?: PartialMessage<Product>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.Product";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "is_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "sort_order", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Product {
    return new Product().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Product {
    return new Product().fromJsonString(jsonString, options);
  }

  static equals(a: Product | PlainMessage<Product> | undefined, b: Product | PlainMessage<Product> | undefined): boolean {
    return proto3.util.equals(Product, a, b);
  }
}

/**
 * メンバーのモデル
 *
 * @generated from message bff.v1.Member
 */
export class Member extends Message<Member> {
  /**
   * メンバーID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 所属している企業のID
   *
   * @generated from field: string company_id = 2;
   */
  companyId = "";

  /**
   * 所属している企業名
   *
   * @generated from field: string company_name = 3;
   */
  companyName = "";

  /**
   * 所属している組織のID
   *
   * @generated from field: string organization_id = 4;
   */
  organizationId = "";

  /**
   * 所属している組織名
   *
   * @generated from field: string organization_name = 5;
   */
  organizationName = "";

  /**
   * メンバー名
   *
   * @generated from field: string name = 6;
   */
  name = "";

  /**
   * メールアドレス
   *
   * @generated from field: string email = 7;
   */
  email = "";

  /**
   * 電話番号
   *
   * @generated from field: string phone_number = 8;
   */
  phoneNumber = "";

  /**
   * 携帯番号
   *
   * @generated from field: string mobile_number = 9;
   */
  mobileNumber = "";

  /**
   * プロフィール画像のURL
   *
   * @generated from field: string image_url = 10;
   */
  imageUrl = "";

  /**
   * 役割
   *
   * @generated from field: bff.v1.MemberRole role = 11;
   */
  role = MemberRole.UNKNOWN;

  /**
   * ステータス
   *
   * @generated from field: bff.v1.MemberStatus status = 12;
   */
  status = MemberStatus.UNKNOWN;

  /**
   * plan_codeをキーにしたユーザーロール設定のマップ
   *
   * @generated from field: bff.v1.UserRoleSettingMap user_role_map = 13;
   */
  userRoleMap?: UserRoleSettingMap;

  /**
   * plan_codeをキーにした現在有効なベースライセンスのマップ
   *
   * @generated from field: map<string, bff.v1.License> base_license_map = 14;
   */
  baseLicenseMap: { [key: string]: License } = {};

  /**
   * plan_codeをキーにした現在有効なオプションライセンスのマップ
   *
   * @generated from field: map<string, bff.v1.License> option_license_map = 15;
   */
  optionLicenseMap: { [key: string]: License } = {};

  /**
   * 言語設定
   *
   * @generated from field: bff.v1.Language language = 16;
   */
  language = Language.UNKNOWN;

  /**
   * ユーザーロールコードのリスト
   *
   * @generated from field: repeated string user_role_codes = 17;
   */
  userRoleCodes: string[] = [];

  /**
   * 作成日時
   *
   * @generated from field: bff.v1.Date createdAt = 18;
   */
  createdAt?: Date;

  constructor(data?: PartialMessage<Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "mobile_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "role", kind: "enum", T: proto3.getEnumType(MemberRole) },
    { no: 12, name: "status", kind: "enum", T: proto3.getEnumType(MemberStatus) },
    { no: 13, name: "user_role_map", kind: "message", T: UserRoleSettingMap },
    { no: 14, name: "base_license_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: License} },
    { no: 15, name: "option_license_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: License} },
    { no: 16, name: "language", kind: "enum", T: proto3.getEnumType(Language) },
    { no: 17, name: "user_role_codes", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "createdAt", kind: "message", T: Date },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Member {
    return new Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Member {
    return new Member().fromJsonString(jsonString, options);
  }

  static equals(a: Member | PlainMessage<Member> | undefined, b: Member | PlainMessage<Member> | undefined): boolean {
    return proto3.util.equals(Member, a, b);
  }
}

/**
 * ライセンスのモデル
 *
 * @generated from message bff.v1.License
 */
export class License extends Message<License> {
  /**
   * 契約プランID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * プロダクト名
   *
   * @generated from field: string product_name = 2;
   */
  productName = "";

  /**
   * プランID
   *
   * @generated from field: int32 plan_id = 3;
   */
  planId = 0;

  /**
   * プラン種別
   *
   * @generated from field: bff.v1.PlanType plan_type = 4;
   */
  planType = PlanType.PLAN_TYPE_UNKNOWN;

  /**
   * プランコード
   *
   * @generated from field: string plan_code = 5;
   */
  planCode = "";

  /**
   * プラン名
   *
   * @generated from field: string plan_name = 6;
   */
  planName = "";

  /**
   * プラン名(英語)
   *
   * @generated from field: string eng_plan_name = 7;
   */
  engPlanName = "";

  /**
   * 開始日
   *
   * @generated from field: bff.v1.Date start_date = 8;
   */
  startDate?: Date;

  /**
   * 終了日
   *
   * @generated from field: bff.v1.Date end_date = 9;
   */
  endDate?: Date;

  /**
   * 契約タイプ
   *
   * @generated from field: bff.v1.LicenseContractType contract_type = 10;
   */
  contractType = LicenseContractType.CONTRACT_TYPE_UNKNOWN;

  /**
   * ライセンスステータス
   *
   * @generated from field: bff.v1.LicenseStatus status = 11;
   */
  status = LicenseStatus.LICENSE_STATUS_UNKNOWN;

  /**
   * ユーザーがユーザーロール設定可能かどうか
   *
   * @generated from field: bool is_user_role_configurable = 12;
   */
  isUserRoleConfigurable = false;

  /**
   * ユーザーへ表示可能なプロダクトかどうか
   *
   * @generated from field: bool is_product_public = 13;
   */
  isProductPublic = false;

  constructor(data?: PartialMessage<License>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.License";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "plan_id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "plan_type", kind: "enum", T: proto3.getEnumType(PlanType) },
    { no: 5, name: "plan_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "plan_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "eng_plan_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "start_date", kind: "message", T: Date },
    { no: 9, name: "end_date", kind: "message", T: Date },
    { no: 10, name: "contract_type", kind: "enum", T: proto3.getEnumType(LicenseContractType) },
    { no: 11, name: "status", kind: "enum", T: proto3.getEnumType(LicenseStatus) },
    { no: 12, name: "is_user_role_configurable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "is_product_public", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): License {
    return new License().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): License {
    return new License().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): License {
    return new License().fromJsonString(jsonString, options);
  }

  static equals(a: License | PlainMessage<License> | undefined, b: License | PlainMessage<License> | undefined): boolean {
    return proto3.util.equals(License, a, b);
  }
}

/**
 * プラン毎に設定できるユーザーロールのモデル
 *
 * @generated from message bff.v1.UserRole
 */
export class UserRole extends Message<UserRole> {
  /**
   * ユーザーロールID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * ユーザーロール識別用コード
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * ユーザーロール名
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * ユーザーロール名(英語)
   *
   * @generated from field: string eng_name = 4;
   */
  engName = "";

  /**
   * 有効・無効
   *
   * @generated from field: bff.v1.UserRoleStatus status = 5;
   */
  status = UserRoleStatus.UNKNOWN;

  /**
   * ユーザーロールの説明用メモ
   *
   * @generated from field: string memo = 6;
   */
  memo = "";

  /**
   * ユーザーロールの説明用メモ(英語)
   *
   * @generated from field: string eng_memo = 7;
   */
  engMemo = "";

  /**
   * デフォルトのユーザーロールかどうか
   *
   * @generated from field: bool is_default = 8;
   */
  isDefault = false;

  /**
   * マスターのユーザーロールかどうか
   *
   * @generated from field: bool is_master = 9;
   */
  isMaster = false;

  constructor(data?: PartialMessage<UserRole>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.UserRole";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(UserRoleStatus) },
    { no: 6, name: "memo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "eng_memo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_master", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRole {
    return new UserRole().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRole {
    return new UserRole().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRole {
    return new UserRole().fromJsonString(jsonString, options);
  }

  static equals(a: UserRole | PlainMessage<UserRole> | undefined, b: UserRole | PlainMessage<UserRole> | undefined): boolean {
    return proto3.util.equals(UserRole, a, b);
  }
}

/**
 * ユーザーロールのリスト
 *
 * @generated from message bff.v1.UserRoles
 */
export class UserRoles extends Message<UserRoles> {
  /**
   * ユーザーロール
   *
   * @generated from field: repeated bff.v1.UserRole user_roles = 1;
   */
  userRoles: UserRole[] = [];

  constructor(data?: PartialMessage<UserRoles>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.UserRoles";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_roles", kind: "message", T: UserRole, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoles {
    return new UserRoles().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoles {
    return new UserRoles().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoles {
    return new UserRoles().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoles | PlainMessage<UserRoles> | undefined, b: UserRoles | PlainMessage<UserRoles> | undefined): boolean {
    return proto3.util.equals(UserRoles, a, b);
  }
}

/**
 * 日付のモデル
 *
 * @generated from message bff.v1.Date
 */
export class Date extends Message<Date> {
  /**
   * 年
   *
   * @generated from field: uint32 year = 1;
   */
  year = 0;

  /**
   * 月
   *
   * @generated from field: uint32 month = 2;
   */
  month = 0;

  /**
   * 日
   *
   * @generated from field: uint32 day = 3;
   */
  day = 0;

  constructor(data?: PartialMessage<Date>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.Date";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 2, name: "month", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 3, name: "day", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Date {
    return new Date().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Date {
    return new Date().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Date {
    return new Date().fromJsonString(jsonString, options);
  }

  static equals(a: Date | PlainMessage<Date> | undefined, b: Date | PlainMessage<Date> | undefined): boolean {
    return proto3.util.equals(Date, a, b);
  }
}

/**
 * @generated from message bff.v1.UserRoleSettingMap
 */
export class UserRoleSettingMap extends Message<UserRoleSettingMap> {
  /**
   * plan_codeをキーにしたユーザーロール設定マップ
   *
   * @generated from field: map<string, bff.v1.UserRoleSettings> setting_map = 1;
   */
  settingMap: { [key: string]: UserRoleSettings } = {};

  constructor(data?: PartialMessage<UserRoleSettingMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.UserRoleSettingMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "setting_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: UserRoleSettings} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleSettingMap {
    return new UserRoleSettingMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleSettingMap {
    return new UserRoleSettingMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleSettingMap {
    return new UserRoleSettingMap().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleSettingMap | PlainMessage<UserRoleSettingMap> | undefined, b: UserRoleSettingMap | PlainMessage<UserRoleSettingMap> | undefined): boolean {
    return proto3.util.equals(UserRoleSettingMap, a, b);
  }
}

/**
 * @generated from message bff.v1.UserRoleSettings
 */
export class UserRoleSettings extends Message<UserRoleSettings> {
  /**
   * ユーザーロール設定
   *
   * @generated from field: repeated bff.v1.UserRoleSetting settings = 1;
   */
  settings: UserRoleSetting[] = [];

  constructor(data?: PartialMessage<UserRoleSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.UserRoleSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: UserRoleSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleSettings {
    return new UserRoleSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleSettings {
    return new UserRoleSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleSettings {
    return new UserRoleSettings().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleSettings | PlainMessage<UserRoleSettings> | undefined, b: UserRoleSettings | PlainMessage<UserRoleSettings> | undefined): boolean {
    return proto3.util.equals(UserRoleSettings, a, b);
  }
}

/**
 * ユーザーロール設定のモデル
 *
 * @generated from message bff.v1.UserRoleSetting
 */
export class UserRoleSetting extends Message<UserRoleSetting> {
  /**
   * ユーザーロールID
   *
   * @generated from field: string user_role_id = 1;
   */
  userRoleId = "";

  /**
   * ユーザーロールコード
   *
   * @generated from field: string user_role_code = 2;
   */
  userRoleCode = "";

  /**
   * ユーザーロール名
   *
   * @generated from field: string user_role_name = 3;
   */
  userRoleName = "";

  /**
   * ユーザーロール名(英語)
   *
   * @generated from field: string user_role_eng_name = 4;
   */
  userRoleEngName = "";

  /**
   * ユーザーロールの説明用メモ
   *
   * @generated from field: string user_role_memo = 5;
   */
  userRoleMemo = "";

  /**
   * ユーザーロールの説明用メモ(英語)
   *
   * @generated from field: string user_role_eng_memo = 6;
   */
  userRoleEngMemo = "";

  /**
   * デフォルトのユーザーロールかどうか
   *
   * @generated from field: bool is_default = 7;
   */
  isDefault = false;

  /**
   * マスターのユーザーロールかどうか
   *
   * @generated from field: bool is_master = 8;
   */
  isMaster = false;

  /**
   * ユーザーロールが設定されているかどうか
   *
   * @generated from field: bool is_setting = 9;
   */
  isSetting = false;

  constructor(data?: PartialMessage<UserRoleSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.UserRoleSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_role_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_role_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user_role_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "user_role_memo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "user_role_eng_memo", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_master", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_setting", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRoleSetting {
    return new UserRoleSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRoleSetting {
    return new UserRoleSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRoleSetting {
    return new UserRoleSetting().fromJsonString(jsonString, options);
  }

  static equals(a: UserRoleSetting | PlainMessage<UserRoleSetting> | undefined, b: UserRoleSetting | PlainMessage<UserRoleSetting> | undefined): boolean {
    return proto3.util.equals(UserRoleSetting, a, b);
  }
}

/**
 * 組織のモデル
 *
 * @generated from message bff.v1.Organization
 */
export class Organization extends Message<Organization> {
  /**
   * 組織ID
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * 所属している企業のID
   *
   * @generated from field: string company_id = 2;
   */
  companyId = "";

  /**
   * 企業名
   *
   * @generated from field: string company_name = 3;
   */
  companyName = "";

  /**
   * 企業名(英語)
   *
   * @generated from field: string company_eng_name = 4;
   */
  companyEngName = "";

  /**
   * 組織名
   *
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * 組織名(英語)
   *
   * @generated from field: string eng_name = 6;
   */
  engName = "";

  /**
   * 略称
   *
   * @generated from field: string abbreviation = 7;
   */
  abbreviation = "";

  /**
   * ロゴ画像のURL
   *
   * @generated from field: string image_url = 8;
   */
  imageUrl = "";

  /**
   * 作成日時
   *
   * @generated from field: google.protobuf.Timestamp created_at = 9;
   */
  createdAt?: Timestamp;

  /**
   * 更新日時
   *
   * @generated from field: google.protobuf.Timestamp updated_at = 10;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<Organization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.Organization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "company_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "company_eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "abbreviation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "image_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "created_at", kind: "message", T: Timestamp },
    { no: 10, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization {
    return new Organization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJsonString(jsonString, options);
  }

  static equals(a: Organization | PlainMessage<Organization> | undefined, b: Organization | PlainMessage<Organization> | undefined): boolean {
    return proto3.util.equals(Organization, a, b);
  }
}

/**
 * プランのモデル
 *
 * @generated from message bff.v1.Plan
 */
export class Plan extends Message<Plan> {
  /**
   * プランID
   *
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * プランコード
   *
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * プラン名
   *
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * プラン名(英語)
   *
   * @generated from field: string eng_name = 4;
   */
  engName = "";

  /**
   * プラン種別
   *
   * @generated from field: bff.v1.PlanType type = 5;
   */
  type = PlanType.PLAN_TYPE_UNKNOWN;

  constructor(data?: PartialMessage<Plan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.Plan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "eng_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "type", kind: "enum", T: proto3.getEnumType(PlanType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Plan {
    return new Plan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Plan {
    return new Plan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Plan {
    return new Plan().fromJsonString(jsonString, options);
  }

  static equals(a: Plan | PlainMessage<Plan> | undefined, b: Plan | PlainMessage<Plan> | undefined): boolean {
    return proto3.util.equals(Plan, a, b);
  }
}

/**
 * 利用規約への同意モデル
 *
 * @generated from message bff.v1.Term
 */
export class Term extends Message<Term> {
  /**
   * 規約名
   *
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * 規約バージョンID
   *
   * @generated from field: int32 versionId = 2;
   */
  versionId = 0;

  /**
   * 規約バージョン
   *
   * @generated from field: string version = 3;
   */
  version = "";

  /**
   * 規約URL
   *
   * @generated from field: string url = 4;
   */
  url = "";

  /**
   * ライセンスID
   *
   * @generated from field: string license_id = 5;
   */
  licenseId = "";

  /**
   * 同意ステータス
   *
   * @generated from field: bff.v1.TermAgreementStatus status = 6;
   */
  status = TermAgreementStatus.UNKNOWN;

  constructor(data?: PartialMessage<Term>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "bff.v1.Term";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "versionId", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "version", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "license_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(TermAgreementStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Term {
    return new Term().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Term {
    return new Term().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Term {
    return new Term().fromJsonString(jsonString, options);
  }

  static equals(a: Term | PlainMessage<Term> | undefined, b: Term | PlainMessage<Term> | undefined): boolean {
    return proto3.util.equals(Term, a, b);
  }
}

