import { MemberRole } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'

import { TermsAgreementModal } from './TermsModal'

export const TermAgreement: React.FC = () => {
  const me = useLoginUser()
  if (me.role !== MemberRole.ADMIN) {
    return null
  }
  return <TermsAgreementModal />
}
