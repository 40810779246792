import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { useQueryClient } from '@tanstack/react-query'

import { useToast } from '@enechain/ecloud-designsystem'

import {
  getMember,
  saveMember,
} from '~/gen/proto/bff/v1/admin-BffAdminService_connectquery'
import {
  getMemberProfile,
  listMembers,
} from '~/gen/proto/bff/v1/common-BffCommonService_connectquery'

export function useMemberMutation(
  onSuccess: () => void,
  memberId: string,
): ReturnType<typeof useMutation> {
  const { showToast } = useToast()
  const queryClient = useQueryClient()
  return useMutation(saveMember, {
    onError: (error) => {
      showToast({
        toastMessage: error.message,
        status: 'error',
      })
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: [
          createConnectQueryKey(listMembers, {}),
          createConnectQueryKey(getMemberProfile, {}),
        ],
      })
      void queryClient.refetchQueries({
        queryKey: createConnectQueryKey(getMember, { memberId: memberId }),
      })
      onSuccess()
    },
  })
}
