import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { listPendingTerms } from '~/gen/proto/bff/v1/admin-BffAdminService_connectquery'
import { Term } from '~/gen/proto/bff/v1/model_pb'

export function usePendingTerms(): Term[] {
  const { t } = useTranslation()
  const res = useSuspenseQuery(
    listPendingTerms,
    {},
    {
      select: (data) => data.terms,
    },
  )
  if (res.isError || res.data === undefined) {
    throw new Error(t('message.fetchFail', { entity: t('domain.terms') }))
  }
  return res.data
}
